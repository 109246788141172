<template>
  <div class="request--book-promo request--book-user" v-if="!currentPhone">
    <div class="left--part">
      <div class="title--text">
        {{ $t('general.phoneInput') }}
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('localPhone') }">
        <cleave
          id="phone"
          v-model="localPhone"
          :options="phoneCleaveOption"
          class="basic--input"
          type="text"
          name="phoneNumber"
        />
        <span class="val-error" v-if="validation.hasError('localPhone')">{{
          validation.firstError('localPhone')
        }}</span>
      </div>
    </div>
    <!--    <div class="right&#45;&#45;part">-->
    <!--      <input class="basic&#45;&#45;input" v-model="promoCode" />-->
    <!--      <span class="error&#45;&#45;text" v-show="promoWarning"> {{ promoWarning }}</span>-->
    <!--      <div v-if="chosenPromo">-->
    <!--        <span v-show="!promoWarning"> {{ chosenPromo.title }}</span>-->
    <!--        <a :href="`/promo/detail/${chosenPromo.banner_uuid}`" target="_blank">Lihat Detail Promo</a>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';

export default {
  name: 'user-data-section',
  mixins: [LodashMixin],
  components: {
    Cleave,
  },
  data() {
    return {
      phoneCleaveOption: {
        phone: true,
        phoneRegionCode: 'id',
      },
    };
  },
  computed: {
    ...mapState({
      currentPhone: (state) => (state.global.user ? state.global.user.phone : null),
    }),
    localPhone: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localPhone;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_PHONE', val);
      },
    },
  },
  validators: {
    localPhone(value) {
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
  created() {},
  methods: {
    showModal(modalName) {
      this.$modal.show(modalName);
    },
  },
};
</script>

<style scoped></style>
